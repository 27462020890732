export default function (eventName, data = {}, eventId) {
    /*
    if (typeof window.fbq === 'undefined' || !eventName) {
        return
    }
    */
    const poll = setInterval(() => {
        if (typeof window.fbq === 'undefined' || !window.fbq) {
            console.warn("window.fbq NOT found.. returning without invoking: " + eventName)
            return;
        }
        clearInterval(poll);

        if (data) {
            if (eventId) {
                fbq("track", eventName, data, { "eventID": eventId });
            } else {
                fbq("track", eventName, data);                
            }
        } else {
            fbq("track", eventName);
        }
    }, 10);
}